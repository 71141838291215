import { useMemo, useState } from 'react';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  Chip,
  Grid,
  Dialog,
  Typography,
  Switch,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Header from "../../../../components/Header";
import { fakeData, documents_required } from './makeData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from 'react-dropzone';
import axios from "../../../../axiosConfig"
import ConfirmDialog from '../../../../elements/ConfirmDialog/ConfirmDialog';

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedNames, setSelectedNames] = useState([]);
  const [status, setStatus] = useState('Active');
  const [subCategories, setSubCategories] = useState([
    { name: '', documents: [] }
  ]);
  const [description, setDescription] = useState('');
  const [addServiceModal, setAddServiceDialogOpen] = useState(false);
  const [images, setUploadedImages] = useState();
  const [applicationFee, setAppFee] = useState(undefined);
  const [serviceCharge, setServiceCharge] = useState(undefined);
  const [editServiceModal, setEditServiceModal] = useState(false);
  const [editId, setEditId] = useState('');

  const [dialogInfo, setDialogInfo] = useState({ open: false, title: '', message: '' });
  const handleCloseDialog = () => setDialogInfo({ open: false, title: '', message: '' });


  // Handles subcategory name change
const handleSubCategoryChange = (index, value) => {
  const updatedSubCategories = [...subCategories];
  updatedSubCategories[index].name = value;
  setSubCategories(updatedSubCategories);
};

// Handles document change for a specific subcategory
const handleDocumentsChange = (index, newDocuments) => {
  const updatedSubCategories = [...subCategories];
  updatedSubCategories[index].documents = newDocuments;
  setSubCategories(updatedSubCategories);
};

// Adds a new subcategory
const handleAddSubCategory = () => {
  setSubCategories([...subCategories, { name: '', documents: [] }]);
};

// Removes a subcategory
const handleRemoveSubCategory = (index) => {
  const updatedSubCategories = subCategories.filter((_, i) => i !== index);
  setSubCategories(updatedSubCategories);
};

const renderSubCategoryFields = () => {
  return subCategories.map((subcategory, index) => (
    <Grid container spacing={2} key={index}>
      <Grid item xs={5}>
        <TextField
          label={`Subcategory ${index + 1}`}
          value={subcategory.name}
          fullWidth
          onChange={(e) => handleSubCategoryChange(index, e.target.value)}
        />
      </Grid>
      <Grid item xs={6} sx={{mb: 2 }}>
        <Autocomplete
          multiple
          options={documents_required}
          freeSolo
          disableCloseOnSelect // Keeps the dropdown open
          value={subcategory.documents}
          onChange={(event, newValue) => handleDocumentsChange(index, newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                key={option}
                label={option}
                {...getTagProps({ index })} // Correctly pass index for each tag
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Documents Required"
              placeholder="Add documents"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={() => handleRemoveSubCategory(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  ));
};


  const columns = useMemo(
    () => [
      // {
      //   accessorKey: '_id',
      //   header: 'Id',
      //   enableEditing: false,
      //   size: 80,
      // },
      {
        accessorKey: 'name',
        header: 'Service',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.service,
          helperText: validationErrors?.service,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              service: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              service: undefined,
            }),
        },
        Cell: ({ row }) => (
          <div>
            <span style={{ color: row.original.status === 'Active' ? 'green' : 'red', fontWeight:"bolder" }}>
              {row.original.status}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'timeline',
        header: 'Timeline',
        muiEditTextFieldProps: {
          error: !!validationErrors?.timeline,
          helperText: validationErrors?.timeline,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              timeline: undefined,
            }),
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser(applicationFee, serviceCharge, selectedNames, images, status, subCategories, description, setDialogInfo);
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser(applicationFee, serviceCharge, selectedNames, images, status, subCategories, description, editId);
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, table }) => {
    var newValidationErrors = validateUser(values);
    newValidationErrors = {
      ...newValidationErrors,
      description: description === '' ? 'Description is Required' : '',
    }
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    var newValidationErrors = validateUser(values);
    newValidationErrors = {
      ...newValidationErrors,
      description: description === '' ? 'Description is Required' : '',
    }
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this Record?')) {
      deleteUser(row.original['_id']);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <Dialog
        open={addServiceModal}
        // onClose={() => {
        //   setAddServiceDialogOpen(false);
        // }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle variant="h3">Add New Service</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {internalEditComponents.map((component) => (
                component.props.cell.column.id !== 'status' && component.props.cell.column.id !== 'description' && (
                  <div key={component.props.cell.column.id}>
                    {component}
                  </div>
                )
              ))}
              <TextField
                label="Application Fee"
                value={row.original.application_fee}
                error={!!validationErrors?.application_fee}
                helperText={validationErrors?.application_fee}
                fullWidth
                sx={{ marginTop: "1rem" }}
                onChangeCapture={(e) => setAppFee(e.target.value)}
              />
              <TextField
                label="Service Charge"
                value={row.original.service_charge}
                error={!!validationErrors?.service_charge}
                helperText={validationErrors?.service_charge}
                fullWidth
                sx={{ marginTop: "1rem" }}
                onChangeCapture={(e) => setServiceCharge(e.target.value)}
              />
              {/* Description as TextArea */}
              <TextField
                label="Description"
                multiline
                rows={4}
                value={description}
                fullWidth
                sx={{ marginTop: "1rem" }}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
    
            <Grid item xs={6}>
              {internalEditComponents.map((component) =>
                component.props.cell.column.id === 'status' && (
                  <FormControl fullWidth sx={{ mt: 2, mb: 0.75 }} key={component.props.cell.column.id}>
                    <Typography>Status</Typography>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography color={status === "Active" ? "green" : "gray"}>Active</Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={status === "Active"}
                          onChange={(event) => {
                            const newStatus = event.target.checked ? "Active" : "Inactive";
                            setStatus(newStatus);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography color={status === "Inactive" ? "red" : "gray"}>Inactive</Typography>
                      </Grid>
                    </Grid>
                  </FormControl>
                )
              )}
              <Dropzone
                onDrop={(e) => {
                  setUploadedImages(e[0]);
                }}
                multiple={false}
                accept={{ "image/jpeg": [".jpeg"], "image/png": [".png"] }}
              >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <div
                    {...getRootProps({
                      style: {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: '#b3b3b3',
                        borderStyle: 'dashed',
                        backgroundColor: '#eeeeee',
                        color: '#737373',
                        outline: 'none',
                        transition: 'border .24s ease-in-out',
                      },
                      onDrop: event => event.stopPropagation(),
                    })}
                  >
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop the image here, or click to select the image</p>
                    <ul>
                      {acceptedFiles.map((file) => (
                        <li key={file.name}>
                          {file && <img style={{ width: "100px" }} src={URL.createObjectURL(file)} alt={file.name} />}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Dropzone>
            </Grid>
    
            {/* Subcategories and Documents */}
            <Grid item xs={12}>
              <Button onClick={handleAddSubCategory} variant="outlined" fullWidth sx={{ marginBottom: '1rem' }}>
                Add Subcategory
              </Button>
            </Grid>
            <Grid item xs={12}>
              {subCategories.length > 0 &&
                renderSubCategoryFields()
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons
            variant="text"
            table={table}
            row={row}
            onSubmit={() => {
              // Handle submission logic here
            }}
          />
        </DialogActions>
      </Dialog>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <Dialog
        open={editServiceModal}
        // onClose={() => {
        //   setEditServiceModal(false);
        // }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle variant="h3">Edit Service</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          fullWidth
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {internalEditComponents.map((component) => (
                component.props.cell.column.id !== 'status' && component.props.cell.column.id !== 'description' && (
                  <div key={component.props.cell.column.id}>
                    {component}
                  </div>
                )
              ))}
                <TextField
                  label="Application Fee"
                value={applicationFee}
                error={!!validationErrors?.application_fee}
                helperText={validationErrors?.application_fee}
                fullWidth
                style={{ marginTop: "1rem" }}
                onChangeCapture={(e) => {
                  setAppFee(e.target.value);
                }}
              />
              <TextField
                label="Service Charge"
                value={serviceCharge}
                error={!!validationErrors?.service_charge}
                helperText={validationErrors?.service_charge}
                style={{ marginTop: "1rem" }}
                fullWidth
                onChangeCapture={(e) => {
                  setServiceCharge(e.target.value)
                }}
              />
              {/* Description as TextArea */}
              <TextField
                label="Description"
                multiline
                rows={4}
                value={description}
                fullWidth
                style={{ marginTop: "1rem" }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
            {(internalEditComponents).map((component) =>
                component.props.cell.column.id === 'status' &&
                  <FormControl fullWidth sx={{ mt: 2, mb: 0.75 }} key={component.props.cell.column.id}>
                  <Typography>Status</Typography>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography color={status === "Active" ? "green" : "gray"}>Active</Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={status === "Active"}
                        onChange={(event) => {
                          const newStatus = event.target.checked ? "Active" : "Inactive";
                          setStatus(newStatus);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography color={status === "Inactive" ? "red" : "gray"}>Inactive</Typography>
                    </Grid>
                  </Grid>
                </FormControl>
                )
              }
              <Dropzone
                onDrop={(e) => {
                  setUploadedImages(e[0]);
                }}
                multiple={false}
                accept={{ "image/jpeg": [".jpeg"], "image/png": [".png"] }}
              >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <>
                    <div
                      {...getRootProps({
                        style: {
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '20px',
                          borderWidth: 2,
                          borderRadius: 2,
                          borderColor: '#b3b3b3',
                          borderStyle: 'dashed',
                          backgroundColor: '#eeeeee ',
                          color: '#737373',
                          outline: 'none',
                          transition: 'border .24s ease-in-out',
                          height: "50%",
                        },
                        onDrop: event => event.stopPropagation(),
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop the image here, or click to select the image</p>
                      <ul>
                        {acceptedFiles.map((file) => (
                          <li key={file.name}>
                            {file && <img style={{width: "100px"}} src={URL.createObjectURL(file)} alt={file.name} />}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </Dropzone>
            </Grid>
             {/* Subcategories and Documents */}
             <Grid item xs={12}>
              <Button onClick={handleAddSubCategory} variant="outlined" fullWidth sx={{ marginBottom: '1rem' }}>
                Add Subcategory
              </Button>
            </Grid>
            <Grid item xs={12}>
              {subCategories.length > 0 &&
                renderSubCategoryFields()
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} onSubmit={() => {
          }} />
        </DialogActions>
      </Dialog >
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => {
            table.setEditingRow(row)
            setEditServiceModal(true);
            setSelectedNames(row.original.documents);
            setAppFee(row.original.applicationFee);
            setServiceCharge(row.original.serviceCharge);
            setUploadedImages(row.original.photo);
            setStatus(row.original.status);
            setSubCategories(row.original.subCategories);
            setDescription(row.original.description);
            setEditId(row.original._id);
          }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
          setSubCategories([
            { name: '', documents: [] }
          ]);
          setDescription('');
          setAppFee(undefined);
          setServiceCharge(undefined);
          setAddServiceDialogOpen(true);
        }}
      >
        Add New Service
      </Button>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  return (
    <>
      {/* Material React Table */}
      <MaterialReactTable table={table} />

      {/* Confirm Dialog */}
      <ConfirmDialog
        open={dialogInfo.open}
        onClose={handleCloseDialog}
        title={dialogInfo.title}
        message={dialogInfo.message}
      />
    </>
  );
};

//CREATE hook (post new user to api)
function useCreateUser(applicationFee, serviceFee, documents, imageRaw, status, subCategories, description, setDialogInfo) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      const formData = new FormData();
      formData.append('name', user.name);
      formData.append('timeline', user.timeline);
      formData.append('description', description);
      formData.append('applicationFee', applicationFee);
      formData.append('serviceCharge', serviceFee);
      formData.append('documents', documents);
      formData.append('status', status);
      formData.append('subCategories', JSON.stringify(subCategories));
      // Append the image file if it exists
      if (imageRaw) {
        const renamedImage = new File([imageRaw], imageRaw.name, { type: imageRaw.type });
        formData.append('photo', renamedImage);
      }

      // send api update request here
      try {
        const response = await axios.post("/services/add_new_service", formData,
          {
            "Content-Type" : "multipart/form-data"
          });
          console.log(response)
        if (response.data?.status === 200){
          return response.data.responseData;
        } else {
          throw new Error(response.data.error);
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || 'An error occurred while creating the user');
      }


    },
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) => {
        const usersArray = Array.isArray(prevUsers) ? prevUsers : [];
    
        return [
          ...usersArray,
          {
            ...newUserInfo,
            id: (Math.random() + 1).toString(36).substring(7), // Generate a temporary ID
          },
        ];
      });
    },    
    onSuccess: (data) => {
      setDialogInfo({
        open: true,
        title: 'Success',
        message: 'Service got created successfully!',
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: (error) => {
      setDialogInfo({
        open: true,
        title: 'Error',
        message: `Failed to create user: ${error.message}.`,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      try {
        const response = await axios.post('/services/get_all_services', {});
        return response.data.responseData;
      } catch (e) {
        return fakeData;
      }
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateUser(applicationFee, serviceFee, documents, imageRaw, status, subCategories, description, editId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      const formData = new FormData();
      formData.append('name', user.name);
      formData.append('timeline', user.timeline);
      formData.append('description', description);
      formData.append('applicationFee', applicationFee);
      formData.append('serviceCharge', serviceFee);
      formData.append('documents', documents);
      formData.append('status', status);
      formData.append('subCategories', JSON.stringify(subCategories));

      // Append the image file if it exists
      if (imageRaw && imageRaw.type) {
        const renamedImage = new File([imageRaw], imageRaw.name, { type: imageRaw.type });
        formData.append('photo', renamedImage);
      }
      try{
        const response = await axios.post(`/services/edit/${editId}`,formData,
        {
          "Content-Type" : "multipart/form-data"
        });
          setUploadedImages();
          setAppFee('');
          setServiceCharge('');
          return response.data.responseData;
      }
      catch(e){
        console.log("error", e);
      }
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) => {
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        )
      }
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      //send api update request here
      try {
        const response = await axios.post(`/services/delete/${userId}`, {});
        return response.data.responseData;
      }
      catch (e) {
        return;
      }

    },
    //client side optimistic update
    onMutate: async (userId) => {
      await queryClient.cancelQueries(['users']);

      const previousUsers = queryClient.getQueryData(['users']);

      // Optimistically update to remove the user from the cache
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );

      return { previousUsers };
    },
    onError: (err, userId, context) => {
      // Roll back cache to previous state in case of error
      queryClient.setQueryData(['users'], context.previousUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users']);
    }, //refetch users after mutation, disabled for demo
  });
}


const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <Box m="20px">
      <Header title="Services" subtitle="Manage the servies of clients" />
      <Box
        m="40px 0 0 0"
        height="75vh">
        <Example />
      </Box>
    </Box>
  </QueryClientProvider>
);

export default ExampleWithProviders;

const validateRequired = (value) => {
  return (value == undefined) || (value === '');

};
// const validateEmail = (email) =>
//   !!email.length &&
//   email
//     .toLowerCase()
//     .match(
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
//     );

function validateUser(service) {

  return {
    service: validateRequired(service.name)
      ? 'Service Name is Required'
      : '',
    description: validateRequired(service.description) ? 'Description is required' : '',
  };
}
