import React, { useMemo, useState, useContext } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import axios from '../../../../axiosConfig';
import Header from "../../../../components/Header";
import { Box, Link, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Chip, TextField, Input } from '@mui/material';
import { Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, Message as MessageIcon, UploadFile as UploadFileIcon } from '@mui/icons-material';
import PdfViewer from 'elements/PDF/PdfViewer';
import { AuthContext } from "components/AuthContext";

const statusColors = {
  Initiated: 'warning',
  Processing: 'info',
  Finished: 'success',
};

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openReUpload, setOpenReUpload] = useState(false);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [message, setMessage] = useState("");
  const [newDocuments, setNewDocuments] = useState([]);

  const { user } = useContext(AuthContext);

  const handleOpen = (documents) => {
    setCurrentDocuments(['Canonical.pdf']);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentDocuments([]);
  };

  const handleOpenMessages = (row) => {
    setCurrentRow(row);
    setOpenMessages(true);
  };

  const handleCloseMessages = () => {
    setOpenMessages(false);
    setCurrentRow(null);
  };

  const handleSendMessage = async() => {
  if (message.trim()) {
    if (!currentRow.original.commentsThread) {
      currentRow.original.commentsThread = [];
    }
    currentRow.original.commentsThread.push({ 
      author: 'Client', 
      message: message, 
      fromAndTo: '1', 
      timestamp: new Date().toISOString() 
    });
    const updateData = {
        id: currentRow.original._id,
        commentsThread: currentRow.original.commentsThread
    };
    await updateUser(updateData);
    setMessage("");
  }
};


  const handleOpenReUpload = (row) => {
    setCurrentRow(row);
    setOpenReUpload(true);
  };

  const handleCloseReUpload = () => {
    setOpenReUpload(false);
    setCurrentRow(null);
    setNewDocuments([]);
  };

  const handleFileChange = (e) => {
    setNewDocuments([...e.target.files]);
  };

  const handleReUpload = async () => {
    if (newDocuments.length > 0) {
      const formData = new FormData();
      newDocuments.forEach((file) => {
        formData.append('documents', file);
      });
      formData.append('id', currentRow.original.id);

      await axios.post('/invoices/reupload_documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Refetch data or update local state accordingly
      refetchInvoices();
      handleCloseReUpload();
    }
  };

  const columns = useMemo(() => [
    // { accessorKey: 'id', header: 'Id', enableEditing: false, size: 80 },
    {
      accessorKey: 'service', header: 'Service',
    //   muiEditTextFieldProps: {
    //     required: true,
    //     error: !!validationErrors?.service,
    //     helperText: validationErrors?.service,
    //     onFocus: () => setValidationErrors({ ...validationErrors, service: undefined }),
    //   },
    },
    {
      accessorKey: 'sub_category', header: 'Sub-category',
    //   muiEditTextFieldProps: {
    //     required: true,
    //     error: !!validationErrors?.sub_category,
    //     helperText: validationErrors?.sub_category,
    //     onFocus: () => setValidationErrors({ ...validationErrors, sub_category: undefined }),
    //   },
    },
    {
      accessorKey: 'documents', header: 'Documents',
      Cell: ({ row }) => (
        <Link
          component="button"
          variant="body2"
          onClick={() => handleOpen(row.original.documents)}
        >
          View Documents
        </Link>
      ),
    },
    {
      accessorKey: 'status', header: 'Status',
      Cell: ({ row }) => (
        <Chip
          label={row.original.status}
          color={statusColors[row.original.status]}
          variant="outlined"
        />
      ),
      enableEditing: false,
    },
    {
      accessorKey: 'messages', header: 'Messages', size: 100,
      Cell: ({ row }) => (
        <Tooltip title="Edit Messages">
          <IconButton color="primary" onClick={() => handleOpenMessages(row)}>
            <MessageIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      header: 'Upload',
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Re-upload Documents">
            <IconButton color="primary" onClick={() => handleOpenReUpload(row)}>
              <UploadFileIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], [validationErrors]);
  
  

  const { data: fetchedInvoices = [], isLoading: isLoadingInvoices, isError: isLoadingInvoicesError, isFetching: isFetchingInvoices, refetch: refetchInvoices } = useGetInvoices(user);
  const { mutateAsync: updateUser } = useUpdateInvoice();
  const { mutateAsync: deleteUser } = useDeleteInvoice();

  const openDeleteConfirmModal = async(row) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
        try {
          await deleteUser(row.original._id);
        } catch (error) {
          console.error('Failed to delete invoice:', error);
        }
      }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedInvoices,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableEditing: false,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingInvoicesError
      ? { color: 'error', children: 'Error loading data' }
      : undefined,
    muiTableContainerProps: { sx: { minHeight: '500px' } },
    muiTableBodyCellProps: {},
    state: {
      isLoading: isLoadingInvoices,
      showAlertBanner: isLoadingInvoicesError,
      showProgressBars: isFetchingInvoices,
    },
    // renderRowActions: ({ row, table }) => (
    //   <Box sx={{ display: 'flex', gap: '1rem' }}>
    //     <Tooltip title="Delete">
    //       <span>
    //         <IconButton color="error" onClick={() => openDeleteConfirmModal(row)} disabled={row.original.status !== 'Initiated'}>
    //           <DeleteIcon />
    //         </IconButton>
    //       </span>
    //     </Tooltip>
    //   </Box>
    // ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>View Documents</DialogTitle>
        <DialogContent>
          {currentDocuments.map((document, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Document {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PdfViewer fileUrl={`/documents/${document}`} />
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMessages} onClose={handleCloseMessages} maxWidth="md" fullWidth>
        <DialogTitle>Thread Messages</DialogTitle>
        <DialogContent>
        {currentRow?.original.commentsThread
        .filter((msg) => msg.fromAndTo === "1")
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .map((msg, index) => (
            <Typography key={index} variant="body2" sx={{ padding: '5px', backgroundColor: msg.author === 'Client' ? 'lightblue' : 'lightgreen', borderRadius: '5px', marginBottom: '5px' }}>
            {msg.author}: {msg.message}
            </Typography>
        ))}
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="New Message"
            fullWidth
            multiline
            rows={4}
            sx={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendMessage} variant="contained">Send</Button>
          <Button onClick={handleCloseMessages}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openReUpload} onClose={handleCloseReUpload} maxWidth="md" fullWidth>
        <DialogTitle>Re-upload Documents</DialogTitle>
        <DialogContent>
          <Input
            type="file"
            inputProps={{ multiple: true }}
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReUpload} variant="contained">Upload</Button>
          <Button onClick={handleCloseReUpload}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const validateRequired = (value) => !!value.length;

function validateUser(service) {
  return {
    department: !validateRequired(service.department) ? 'Department is Required' : '',
    service: !validateRequired(service.service) ? 'Service is Required' : '',
  };
}

// Fake hooks and data

function useGetInvoices(user) {
  return useQuery({
    queryKey: ['invoices'],
    queryFn: async () => {
      const response = await axios.post('/invoices/assigned', {detailsId: user.detailsId});
      return response.data.responseData;
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateInvoice() {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async (updateData) => {
        // Make an API call to update the invoice
        const response = await axios.post(`/invoices/update/${updateData.id}`, updateData);
        return response.data;
      },
      onMutate: async (updatedInvoice) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['invoices']);
  
        // Snapshot the previous value
        const previousInvoices = queryClient.getQueryData(['invoices']);
  
        // Optimistically update to the new value
        queryClient.setQueryData(['invoices'], (old) =>
          old.map((invoice) =>
            invoice.id === updatedInvoice.id ? { ...invoice, ...updatedInvoice } : invoice
          )
        );
  
        // Return a context object with the snapshotted value
        return { previousInvoices };
      },
      onError: (err, updatedInvoice, context) => {
        // Roll back to the previous value if the mutation fails
        queryClient.setQueryData(['invoices'], context.previousInvoices);
      },
      onSettled: () => {
        // Always refetch after error or success:
        queryClient.invalidateQueries(['invoices']);
      },
    });
  }

function useDeleteInvoice() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (invoiceID) => {
        const response = await axios.post(`/invoices/delete/${invoiceID}`);
        return response.data;
      },
      onMutate: (invoiceID) => {
        queryClient.setQueryData(['invoices'], (prevInvoices) =>
          prevInvoices?.filter((invoice) => invoice.id !== invoiceID)
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['invoices']);
      },
      onError: (error) => {
        console.error('Error deleting invoice:', error);
      },
    });
  }

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Box m="20px">
        <Header title="TRANSACTIONS" subtitle="List of services availed" />
        <Box
          m="40px 0 0 0"
          height="75vh"
        >
          <Example />
        </Box>
      </Box>
    </QueryClientProvider>
  );
}
