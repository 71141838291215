// store.js or GlobalStore.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../../axiosConfig';

// Create a context for global state
const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [associates, setAssociates] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [clients, setClients] = useState([]);

  const fetchAssociates = async () => {
    try {
      const response = await axios.post('/user/getAllAssociates');
      if (response.data.status === 200) {
        setAssociates(response.data.responseData);
      } else {
        console.error('Failed to fetch associates:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching associates:', error);
    }
  };

  const fetchOutlets = async () => {
    try {
      const response = await axios.post('/user/getAllOutlets');
      if (response.data.status === 200) {
        setOutlets(response.data.responseData);
      } else {
        console.error('Failed to fetch outlets:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching outlets:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.post('/user/getAllClients');
      if (response.data.status === 200) {
        setClients(response.data.responseData);
      } else {
        console.error('Failed to fetch Clients:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching Clients:', error);
    }
  };

  const updateStoreAfterApproval = async () => {
    await Promise.all([fetchAssociates(), fetchOutlets()]);
  };

   // UseEffect to fetch data on component mount
  useEffect(() => {
    fetchAssociates();
    fetchOutlets();
    fetchClients()
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <GlobalStateContext.Provider value={{ associates, outlets, clients, updateStoreAfterApproval }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
