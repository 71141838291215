import React, { useState, useEffect, useContext } from 'react';
import Dashboard from './DashBoard';
import axios from '../../../../axiosConfig';
import { AuthContext } from "components/AuthContext";

const Main = () => {

  const [data, setData] = useState({
    totalServices: 0,
    finishedServices: 0,
    pendingServices: 0,
  });

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const response = await axios.post('/invoices/assigned', {detailsId: user.detailsId});
        const services = response.data.responseData;

        const totalServices = services.length;
        const finishedServices = services.filter(service => service.status === "finished").length;
        const pendingServices = totalServices - finishedServices;

        setData({
          totalServices,
          finishedServices,
          pendingServices,
        });
      } catch (error) {
        console.error('Error fetching services data:', error);
        // Handle error appropriately, maybe set default values or error state
      }
    };

    fetchServicesData();
  }, []); 

  return (
    <div style={{ padding: "15px", height: "100%" }}>
        <Dashboard data={data} />
    </div>
  );
};

export default Main;
