import React, { useContext, useState, useEffect } from 'react';
import { Box, IconButton, useTheme, Menu, MenuItem, Badge, Stack, Typography } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../../../theme";


const Topbar = ({ onTriggerKyc, kycStatus }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKycReminder, setShowKycReminder] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the KYC reminder flag is set
    const reminder = localStorage.getItem('kycReminder');
    if (reminder) {
      setShowKycReminder(true);
    } else {
      setShowKycReminder(false);
    }
  }, [onTriggerKyc, kycStatus]);

  const handleNotificationClick = (event) => {
    if  (showKycReminder) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleKycClick = () => {
    setAnchorEl(null);
    navigate('/home/kyc')
    onTriggerKyc(); // Trigger KYC form in Main component
  };

  const notifications = [
    {
      message: 'You have a pending KYC form submission',
      type: 'kyc',
      onClick: handleKycClick
    },
    // Add more notifications here
  ];

  // Adjust the icon color based on the theme mode
  const iconColor = theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[800];
  const backgroundColor = theme.palette.mode === "dark" ? colors.primary[500] : colors.primary[400];

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={backgroundColor}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon sx={{ color: iconColor }} />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon sx={{ color: iconColor }}/>
          ) : (
            <LightModeOutlinedIcon sx={{ color: iconColor }}/>
          )}
        </IconButton>
        <IconButton onClick={handleNotificationClick}>
          <Badge color="secondary" variant="dot" invisible={!showKycReminder}>
            <NotificationsOutlinedIcon sx={{ color: iconColor }}/>
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleNotificationClose}
          PaperProps={{
            style: {
              maxHeight: '300px',
              width: '250px',
            },
          }}
        >
          {showKycReminder && notifications.map((notification, index) => (
            <MenuItem key={index} onClick={notification.onClick || handleNotificationClose}>
              {notification.message}
            </MenuItem>
          ))}
        </Menu>
        <IconButton>
          <SettingsOutlinedIcon sx={{ color: iconColor }}/>
        </IconButton>
        <IconButton onClick={() => {
          window.sessionStorage.removeItem('authState');
          window.sessionStorage.removeItem('token');
          window.location = '/';
        }}>
          <Stack direction="column" spacing={1} alignItems="center">
            <LogoutIcon sx={{ color: iconColor }} />
            <Typography variant="body2" sx={{ color: 'black', fontWeight: 'bold' }}>
              Logout
            </Typography>
          </Stack>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
