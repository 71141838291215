import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useInView } from 'react-intersection-observer';
import Button from 'elements/Button';
import { FaArrowRight } from 'react-icons/fa';
import './Portfolio.css';

export default function Portfolio({ data }) {
  data = data.filter((item) => item.status === "Active").slice(0, 3);

  const { ref, inView } = useInView({
    triggerOnce: true, // Triggers the animation only once
    threshold: 0.1,    // Adjusts when the animation should start (10% in view)
  });

  return (
    <section className="w-full flex flex-col items-center mt-20 bg-theme-dark py-16">
      {/* Zoom effect section */}
      <div ref={ref} className={`zoom-section ${inView ? 'zoom-in' : ''}`}>
        <h1 className="text-5xl text-white text-center font-bold mb-4">Our Services</h1>
        <p className="font-light text-lg text-gray-300 text-center mb-12">
          We are ready to scale up your business with our exceptional services.
        </p>
      </div>
      

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-6 w-full max-w-7xl">
        {data.map((item, index) => (
          <Fade bottom delay={500 * index} key={index}>
            <div className="service-card relative w-full max-w-xs mx-auto bg-white rounded-lg shadow-lg overflow-hidden group transform transition-transform duration-300 hover:scale-105">
              <div className="flex justify-center pt-6">
                <img src={item.photo} alt={item.name} className="" />
              </div>
              <div className="text-center py-4 px-6">
                <h4 className="text-xl font-semibold text-theme-blue">{item.name}</h4>
              </div>
              <div className="px-6 pb-4 text-gray-600">
                <ul className="list-none space-y-2">
                  {item.subCategories.map((service, i) => (
                    <li key={i} className="flex items-center space-x-2">
                      <FaArrowRight className="text-sm text-theme-purple" />
                      <span>{service.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <Button
                type="link"
                href={`/service/${item._id}`}
                className="absolute bottom-4 right-4 p-2 rounded-full bg-theme-purple text-white transition-transform rotate-45 duration-300 transform hover:rotate-0"
              >
                <FaArrowRight className="w-5 h-5" />
              </Button>
            </div>
          </Fade>
        ))}
      </div>

      <Fade bottom>
        <Button href="/services" type="link" className="flex w-36 h-12 sm:w-40 sm:h-14 lg:w-44 lg:h-16 xl:w-36 xl:h-12 text-white px-5 border border-theme-purple items-center mt-14 rounded-full justify-center transition duration-300 hover:bg-theme-purple hover:text-white">
          <p className="font-normal py-3 lg:text-lg xl:text-base">See More</p>
          <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Button>
      </Fade>
    </section>
  );
}
