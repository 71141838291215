import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import Header from 'parts/Header';
import DiscussForm from 'parts/DiscussForm';
import Footer from 'parts/Footer';
export default class DiscussProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        profession: '',
        address: {
          street: '',
          district: '',
          state: 'Tamil Nadu',
          taluk: '',
          village: '',
          landmark: '',
        },
        register_office_name: '',
      },
      districts: [],
      taluks: [],
      villages: [],
      selectedTaluks: [],
      selectedVillages: []
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.loadExcelData();
  }

  loadExcelData = async () => {
    try {
      const response = await fetch('/documents/district_taluk_village_mapping.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });

      const districtSheet = workbook.Sheets[workbook.SheetNames[0]];
      const districtData = XLSX.utils.sheet_to_json(districtSheet);
      
      const talukSheet = workbook.Sheets[workbook.SheetNames[1]];
      const talukData = XLSX.utils.sheet_to_json(talukSheet);

      const villageSheet = workbook.Sheets[workbook.SheetNames[2]];
      const villageData = XLSX.utils.sheet_to_json(villageSheet);

      this.setState({
        districts: districtData,
        taluks: talukData,
        villages: villageData,
        selectedTaluks: [],
        selectedVillages: [],
      });
    } catch (error) {
      console.error('Error loading Excel data:', error);
    }
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      if (name in prevState.data.address) {
        return {
          data: {
            ...prevState.data,
            address: {
              ...prevState.data.address,
              [name]: value,
            },
          },
        };
      } else {
        return {
          data: {
            ...prevState.data,
            [name]: value,
          },
        };
      }
    });
  };

  handleCityChange = (event) => {
    const districtName = event.target.value;
    const districtCode = this.state.districts.find(d => d.district_name === districtName)?.district_code;
    const filteredTaluks = this.state.taluks.filter(t => t.district_code === districtCode);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        address: {
          ...prevState.data.address,
          district: districtName,
          taluk: '',
          village: '',
        },
      },
      selectedTaluks: filteredTaluks,
      selectedVillages: [],
    }));
  };

  handleTalukChange = (event) => {
    const talukName = event.target.value;
    const { taluks, villages, data } = this.state;
    // Find the taluk code and district code based on the selected taluk name
    const selectedTaluk = taluks.find(t => t.taluk_name === talukName);
    const talukCode = selectedTaluk?.taluk_code;
    const districtCode = selectedTaluk?.district_code;
    // Filter the villages based on both taluk code and district code
    const filteredVillages = villages.filter(v => v.taluk_code === talukCode && v.district_code === districtCode);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        address: {
          ...prevState.data.address,
          taluk: talukName,
          village: '',
        },
      },
      selectedVillages: filteredVillages,
    }));
  };

  handleVillageChange = (event) => {
    const villageName = event.target.value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        address: {
          ...prevState.data.address,
          village: villageName,
        },
      },
    }));
  };

  resetForm = () => {
    this.setState({
      data: {
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        address: {
          street: '',
          district: '',
          state: 'Tamil Nadu',
          taluk: '',
          village: '',
          landmark: '',
        },
        register_office_name: '',
      },
    });
  };

  render() {
    const { data, districts, selectedTaluks, selectedVillages } = this.state;

    return (
      <>
        <Header {...this.props} />
        <DiscussForm
          data={data}
          districts={districts}
          taluks={selectedTaluks}
          villages={selectedVillages}
          onChange={this.onChange}
          handleCityChange={this.handleCityChange}
          handleTalukChange={this.handleTalukChange}
          handleVillageChange={this.handleVillageChange}
          resetForm={this.resetForm}
        />
        <Footer />
      </>
    );
  }
}
