import React, { useContext } from 'react';
import { Box, IconButton, useTheme, Stack, Typography } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { ColorModeContext, tokens } from "../../../../theme";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  // Adjust the icon color based on the theme mode
  const iconColor = theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[800];
  const backgroundColor = theme.palette.mode === "dark" ? colors.primary[500] : colors.primary[400];

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      // Perform logout actions
      window.sessionStorage.removeItem('authState');
      window.sessionStorage.removeItem('token');
      window.location = '/'; // Redirect to login page or home page
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={backgroundColor}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon sx={{ color: iconColor }} />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon sx={{ color: iconColor }} />
          ) : (
            <DarkModeOutlinedIcon sx={{ color: iconColor }} />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon sx={{ color: iconColor }} />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon sx={{ color: iconColor }} />
        </IconButton>

        <IconButton onClick={handleLogout}>
          <Stack direction="column" spacing={1} alignItems="center">
            <LogoutIcon sx={{ color: iconColor }} />
            <Typography variant="body2" sx={{ color: 'black', fontWeight: 'bold' }}>
              Logout
            </Typography>
          </Stack>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
