import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Transactions from "./pages/Transactions";
import DashBoard from "./pages/DashBoard/index";
import KYCForm from "./pages/DashBoard/KYCForm";
import { useNavigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { AssociateProvider, useAssociate } from "./store";


function Client() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [triggerKycForm, setTriggerKycForm] = useState(false);
  const { kycStatus, kycData } = useAssociate();
  const navigate = useNavigate();

  useEffect(() => {
    if (kycStatus === false && (!localStorage.getItem('kycReminder') || localStorage.getItem('kycReminder') !== 'true')) {
      navigate("/home/kyc");
    } else if (kycStatus === true) {
      localStorage.removeItem('kycReminder')
    } else {
      navigate("/home"); // Redirect to the dashboard if KYC is completed or reminder is set
    }
  }, [kycStatus]);

  const handleTriggerKyc = () => {
    setTriggerKycForm(true);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{height: "800px"}}>
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar onTriggerKyc={handleTriggerKyc} kycStatus={kycStatus}/>
            <Routes>
              <Route path="/" element={<DashBoard/>} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/kyc" element={<KYCForm kycData={kycData}/>} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default function App() {
  return (
    <AssociateProvider>
      <Client />
    </AssociateProvider>
  );
}
