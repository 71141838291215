import React, { useState } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Tabs, Tab
} from '@mui/material';
import { useGlobalState } from "../../store";

const AdminAccountList = () => {
  const { associates, outlets, clients } = useGlobalState();
  const [selectedTab, setSelectedTab] = useState(0);

 // Combine all users into a single list
 const allUsers = [...clients, ...associates, ...outlets];

 const handleTabChange = (event, newValue) => {
   setSelectedTab(newValue);
 };

 const renderTable = (accounts) => (
   <TableContainer component={Paper}>
     <Table>
       <TableHead>
         <TableRow>
           <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>S.No</TableCell>
           <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Role</TableCell>
           {(selectedTab === 1 || selectedTab === 2) && <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>UserID</TableCell>}
           <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Name</TableCell>
           {(selectedTab === 1 || selectedTab === 2) && <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Profession</TableCell>}
           <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Email</TableCell>
           <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Mobile</TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
         {accounts.map((account, index) => (
           <TableRow key={account._id}>
             <TableCell>{index + 1}</TableCell>
             <TableCell>{account.role}</TableCell>
             {(selectedTab === 1 || selectedTab === 2) && <TableCell>{account.userid}</TableCell>}
             <TableCell>{account.name}</TableCell>
             {(selectedTab === 1 || selectedTab === 2) && <TableCell>{account.profession}</TableCell>}
             <TableCell>{account.email}</TableCell>
             <TableCell>{account.mobile || account.phoneNumber}</TableCell>
           </TableRow>
         ))}
       </TableBody>
     </Table>
   </TableContainer>
 );

 return (
   <Box p={3}>
     <Typography variant="h4" gutterBottom>
       Admin User List
     </Typography>
     <Tabs
       value={selectedTab}
       onChange={handleTabChange}
       indicatorColor="primary"
       textColor="primary"
       centered
       sx={{ backgroundColor: '#f5f5f5', borderRadius: '4px', mb: 2 }}
     >
       <Tab label="All Users" />
       <Tab label="Associates" />
       <Tab label="Outlets" />
       <Tab label="Clients" />
     </Tabs>

     {selectedTab === 0 && renderTable(allUsers)}
     {selectedTab === 1 && renderTable(associates)}
     {selectedTab === 2 && renderTable(outlets)}
     {selectedTab === 3 && renderTable(clients)}
   </Box>
 );
};

export default AdminAccountList;