import React, { useState, useContext, useEffect } from 'react';
import axios from '../../../../axiosConfig';
import { useForm } from 'react-hook-form';
import { Fade } from 'react-awesome-reveal';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Button from 'elements/Button';
import { AuthContext } from 'components/AuthContext';

function ApplyForService() {
  const { id, sub } = useParams();
  const { user } = useContext(AuthContext);
  const serviceName = JSON.parse(id);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const subcategories = JSON.parse(decodeURIComponent(query.get('additionalData'))) || []; // Extract subcategories from additionalData

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(JSON.parse(sub));
  const [requiredDocuments, setRequiredDocuments] = useState([]); // State for required documents
  const navigate = useNavigate();

  useEffect(() => {
    handleSubcategoryChange(JSON.parse(sub))
  },[useParams()])

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('service', serviceName);
      formData.append('commentsThread', JSON.stringify([{ author: "Client", message: data.comments, fromAndTo: "1", timestamp: new Date().toISOString() }]));
      formData.append('sub_category', selectedSubcategory);
      formData.append('status', "Initiated");
      formData.append('clientName', user.username);
      formData.append('clientEmail', user.username);
      formData.append('fromAndTo', '1');
      
      // Append files (if any)
      uploadedFiles.forEach((file) => formData.append('clientUploadedDocument', file));

      const response = await axios.post('/invoices/add_new_invoice', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.status === 201) toast.success('Application Submitted!');
      else toast.error('Failed to submit application. Please try again.');
      setTimeout(() => {
        navigate('/client');
      }, 2000);
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('Failed to submit application. Please try again.');
    }
  };

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);  // Get the new files
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);  // Append new files to the existing ones
  };
  

  // Handle subcategory change and update required documents
  const handleSubcategoryChange = (val) => {
    setSelectedSubcategory(val);

    // Find the selected subcategory and get its required documents
    const selectedSubcat = subcategories.find(subcat => subcat.name === val);
    setRequiredDocuments(selectedSubcat ? selectedSubcat.documents : []);
  };

  const handleBackClick = () => {
    navigate('/client');
  };

  return (
    <section className="container mx-auto mt-10 px-6 sm:px-12">
      <Fade bottom>
        <div className="bg-white shadow-md rounded-lg p-8">
          <div className="flex justify-between items-center mb-6">
            <Button
              type="button"
              onClick={handleBackClick}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <svg className="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
              Go Back
            </Button>
          </div>
          
          <h1 className="text-3xl text-theme-blue font-bold mb-6 text-center">Apply for {serviceName}</h1>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">Service</label>
              <input
                type="text"
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={serviceName}
                readOnly
              />
            </div>

            <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Subcategory</label>
            <div className="relative">
              <select
                key={selectedSubcategory}
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-blue-500 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition duration-200 ease-in-out"
                value={selectedSubcategory}
                onChange={(e) => handleSubcategoryChange(e.target.value)}
              >
                <option value="">Select a subcategory</option>
                {subcategories.map((subcategory, index) => (
                  <option key={index} value={subcategory.name}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
              {/* Dropdown arrow */}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M5.5 7L10 12l4.5-5h-9z" />
                </svg>
              </div>
            </div>
            {errors.subcategory && (
              <p className="text-red-500 text-xs italic mt-2">
                Please select a subcategory.
              </p>
            )}
          </div>

            {/* Display required documents for the selected subcategory */}
            {requiredDocuments.length > 0 && (
              <div className="mb-6 bg-blue-50 p-4 rounded-lg shadow-md">
                <label className="block text-lg font-semibold text-blue-700 mb-3">
                  Required Documents for {selectedSubcategory}
                </label>
                <ul
                  className="grid gap-x-8 gap-y-2"
                  style={{
                    color: "black",
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                  }}
                >
                  {requiredDocuments.map((doc, index) => (
                    <li key={index} className="flex items-center">
                      <svg
                        className="w-4 h-4 mr-2 text-blue-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                      {doc}
                    </li>
                  ))}
                </ul>
              </div>
            )}


            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">Upload Documents</label>
              <div className="relative">
                <input
                  type="file"
                  multiple
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gradient-to-r file:from-purple-500 file:to-blue-600 file:text-white hover:file:from-blue-600 hover:file:to-purple-500"
                  {...register('documents', { required: true })}
                  onChange={handleFileUpload}
                />
                {errors.documents && <p className="text-red-500 text-xs italic mt-2">Please upload the required documents.</p>}
              </div>
              {uploadedFiles.length > 0 && (
                <ul className="mt-4 space-y-2">
                  {uploadedFiles.map((file, index) => (
                    <li key={index} className="flex items-center text-gray-700">
                      <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      {file.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">Comments</label>
              <textarea
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                rows="5"
                {...register('comments')}
              />
            </div>

            <div className="flex items-center justify-center">
              <Button
                type="submit"
                className="w-40 h-12 bg-gradient-to-r from-purple-500 to-blue-600 text-white items-center justify-center rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-500"
              >
                Submit Application
              </Button>
            </div>
          </form>
        </div>
      </Fade>
      <ToastContainer />
    </section>
  );
}

export default ApplyForService;
