import React, { useState, useContext, useEffect } from 'react';
import { Box, TextField, Button, Typography, Stack } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from '../../../../axiosConfig';
import { AuthContext } from "components/AuthContext";
import { useAssociate } from "../../store";

const KYCForm = () => {
  const [formData, setFormData] = useState({
    aadhaarNumber: '',
    aadhaarDocument: null,
    address: '',
    bankAccountNumber: '',
    ifscCode: '',
    bankName: '',
    bankBranch: '',
  });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { kycData, setKycData, setKycStatus } = useAssociate(); 

  useEffect(() => {
    if (kycData) {
      setFormData({
        aadhaarNumber: kycData.aadhaarNumber || '',
        address: kycData.address || '',
        bankAccountNumber: kycData.bankDetails?.accountNumber || '',
        ifscCode: kycData.bankDetails?.ifscCode || '',
        bankName: kycData.bankDetails?.bankName || '',
        bankBranch: kycData.bankDetails?.branch || '',
        aadhaarDocument: null, // Leave document as null for now
      });
    }
  }, [kycData]);

  const handleKycSubmit = async () => {
    if (!formData.aadhaarNumber || !formData.address || !formData.bankName || !formData.bankBranch || !formData.bankAccountNumber || !formData.ifscCode) {
      toast.error("Please fill in all the fields!");
      return;
    }
    try {
      const endpoint = kycData ? "/user/updateKYC" : "/user/createKYC";
      const response = await axios.post(endpoint, {
        userId: user.id,
        aadhaarNumber: formData.aadhaarNumber,
        aadhaarDocument: "testing", // Handle document upload separately
        address: formData.address,
        bankDetails: {
          bankName: formData.bankName,
          branch: formData.bankBranch,
          accountNumber: formData.bankAccountNumber,
          ifscCode: formData.ifscCode,
        },
      });

      if (response.data.status === 200) {
        toast.success(response.data.message);
        localStorage.removeItem('kycReminder');
        // Update the context with new KYC data and status
        setKycData(response.data.responseData); // Assuming the response contains updated KYC data
        setKycStatus(true); // Since it's now completed
        setTimeout(() => {
          navigate("/home");
        }, 4000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting KYC", error);
      toast.error("Failed to submit KYC");
    }
  };

  const handleKycClose = () => {
    localStorage.setItem('kycReminder', 'true');
    navigate("/home");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, aadhaarDocument: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleKycSubmit();
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: '8px',
        boxShadow: 3,
        border: '1px solid #ddd',
        maxWidth: '600px',
        mx: 'auto',
        mt: 4,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {kycData ? "Update KYC Form" : "KYC Form"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Aadhaar Number"
            name="aadhaarNumber"
            value={formData.aadhaarNumber}
            onChange={handleChange}
            variant="outlined"
          />
          <Button
            variant="contained"
            component="label"
            sx={{ mb: 2 }}
          >
            Upload Aadhaar Document
            <input
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Bank Account Number"
            name="bankAccountNumber"
            value={formData.bankAccountNumber}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Bank Name"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Bank Branch"
            name="bankBranch"
            value={formData.bankBranch}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="IFSC Code"
            name="ifscCode"
            value={formData.ifscCode}
            onChange={handleChange}
            variant="outlined"
          />
          
          <Stack direction="row" spacing={2}>
          {!kycData && <Button
              variant="outlined"
              color="secondary"
              onClick={handleKycClose}
            >
              Not Now
            </Button>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {kycData ? "Update" : "Submit"}
            </Button>
          </Stack>
        </Stack>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default KYCForm;
