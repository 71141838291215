import { tokens } from "../../../theme";

export const district_mapping =  {
  "Ariyalur": "ARI",
  "Chengalpattu": "CNG",
  "Chennai": "CHE",
  "Coimbatore": "CBE",
  "Cuddalore": "CUD",
  "Dharmapuri": "DRM",
  "Dindigul": "DGL",
  "Erode": "ERD",
  "Kallakurichi": "KAL",
  "Kanchipuram": "KAN",
  "Kanniyakumari": "KNK",
  "Karur": "KAR",
  "Krishnagiri": "KRS",
  "Madurai": "MDU",
  "Mayiladuthurai": "MYL",
  "Nagapattinam": "NAG",
  "Namakkal": "NAM",
  "Nilgiris": "NIL",
  "Perambalur": "PER",
  "Pudukkottai": "PDK",
  "Ramanathapuram": "RAM",
  "Ranipet": "RAN",
  "Salem": "SLM",
  "Sivagangai": "SVG",
  "Tenkasi": "TNK",
  "Thanjavur": "TNJ",
  "Theni": "THN",
  "Thoothukudi": "TUT",
  "Tiruchirappalli": "TRY",
  "Tirunelveli": "TRV",
  "Tirupathur": "TPA",
  "Tiruppur": "TPR",
  "Tiruvallur": "TLR",
  "Tiruvannamalai": "TVM",
  "Tiruvarur": "TVR",
  "Vellore": "VLR",
  "Viluppuram": "VLP",
  "Virudhunagar": "VRD"
}

export const professionMapping = {
  "Lawyer" : "BL",
  "Chartered Accountant" : "CA",
  "Company Secretary" : "CS",
  "Cost Management Accountant" : "CMA",
  "Document Writer" : "DW",
  "Tax Consultants" : "TC",
  "Other - Degree Holder" : "OT",
};


export const mockBarDataNew = [
  {
    "department": "GST Department",
    "Service A": 130,
    "Service AColor": "hsl(52, 70%, 50%)",
    "Service B": 90,
    "Service BColor": "hsl(351, 70%, 50%)",
    "Service C": 70,
    "Service CColor": "hsl(214, 70%, 50%)",
  },
  {
    "department": "Govt Department",
    "Service D": 170,
    "Service DColor": "hsl(34, 70%, 50%)",
    "Service E": 140,
    "Service EColor": "hsl(88, 70%, 50%)",
    "Service F": 110,
    "Service FColor": "hsl(176, 70%, 50%)",
  },
  {
    "department": "Tax Department",
    "Service G": 100,
    "Service GColor": "hsl(72, 70%, 50%)",
    "Service H": 120,
    "Service HColor": "hsl(123, 70%, 50%)",
    "Service I": 150,
    "Service IColor": "hsl(260, 70%, 50%)",
  },
];
