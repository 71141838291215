// Require the necessary modules
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

// Create a higher-order component (HOC) to guard routes
const withAuthGuard = (WrappedComponent, allowedRoles) => {
  // Define the HOC component
  const GuardedRoute = (props) => {
    const { user, loading } = useContext(AuthContext);

    // If loading is true, return null or a loading spinner
    if (loading) {
      return null; // or loading spinner component
    }
    // Check if user has one of the allowed roles
    const hasPermission = user && allowedRoles.includes(user.role);

    // Render the wrapped component if user has permission, else redirect
    return hasPermission ? <WrappedComponent {...props} /> : <Navigate to="/unauthorized" />;
  };

  return <GuardedRoute />;
};

export default withAuthGuard;
