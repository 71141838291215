const commonFunctions = {

    emailValidator : (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    },

    phoneNumberVaidator : (numberString) => {
        const regex = /^(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/;
        return regex.test(numberString);
    },

    userLoggedInCheck : () => {
        const loggedIn = window.sessionStorage.getItem("authState") ? true : false;
        return loggedIn;
    },

    convertToBase64 : (file) => {
        return new Promise((resolve, reject)=>{
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(reader.error);
        });
    },

    revertToImage : (file) => {
        const regex = /^data:(image\/[a-zA-Z]+);base64,/;
        file = file.replace(regex, '');
        console.log(file)
        const byteCharacters = atob(file);
        const byteNumbers = new Array(byteCharacters.length);
    
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
    
        return URL.createObjectURL(blob);
    }


}

export default commonFunctions;