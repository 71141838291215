import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.mjs';
import { Button, Box } from '@mui/material';

// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(fileUrl);
        const pdf = await loadingTask.promise;
        setNumPages(pdf.numPages);
        
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
  
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
          document.getElementById('pdf-container').appendChild(canvas);
        }
      } catch (error) {
        setLoadingError(error.message);
      }
    };

    loadPdf();
  }, [fileUrl]);

  if (loadingError) {
    return <div>Error loading PDF: {loadingError}</div>;
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <div id="pdf-container"></div>
      <Button 
        variant="contained" 
        color="primary" 
        target="_blank" 
        rel="noopener noreferrer" // For security reasons
        href={fileUrl} 
        download="document.pdf" 
        sx={{ mt: 2 }}
      >
        Download PDF
      </Button>
    </Box>
  );
};

export default PdfViewer;
