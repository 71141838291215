import { string } from "prop-types";

export const User = {
    id: string,
    department: string,
    service: string,
    timeline: string,
  };
  
  export const fakeData = [
    {
      id: '1',
      department: 'GST Department',
      service: 'GST Registration',
      timeline: '2 days',
      state: 'Ohio',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '2',
      department: 'Govt Department',
      service: 'temp text',
      timeline: '1 day',
      state: 'Rhode Island',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '3',
      department: 'Tax Department',
      service: 'temp text',
      timeline: '2 days',
      state: 'California',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '4',
      department: 'GST Department',
      service: 'GST Registration',
      timeline: '3 days',
      state: 'Montana',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '5',
      department: 'GST Department',
      service: 'GST Registration',
      timeline: '3 days',
      state: 'Colorado',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '6',
      department: 'GST Department',
      service: 'GST Registration',
      timeline: '2 days',
      state: 'New York',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '7',
      department: 'Tax Department',
      service: 'temp text',
      timeline: '1 day',
      state: 'Montana',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
    {
      id: '8',
      department: 'Tax Department',
      service: 'temp text',
      timeline: '1 day',
      state: 'Nebraska',
      application_fee: "500rs",
      service_charge: "Individul - 1500 to 3000 ,Firm - 3000 to 8000, Company - 5000 to 20000",
    },
  ];
  
  //50 us states array
  export const usStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
  ];

  export const documents_required = [
    'Pan',
    'Aadhar card',
    'Driving liscence',
    'Voter ID',
    'Rental Deed',
    'Name of the Business',
    'Passport Size Photo',
  ];