import React, { Component } from 'react';

import Header from 'parts/Header';
import HeroPortfolio from 'parts/HeroPortfolio';
import Discuss from 'parts/Discuss';
import Footer from 'parts/Footer';
import AllPortfolio from 'parts/AllPortfolio';

import axios from '../axiosConfig';
import { Height } from '@mui/icons-material';

export default class ProjectPage extends Component {

  state = {
    portfolioData: [], // Initialize state to store the portfolio data
    loading: true,     // State to manage loading state
    error: null,       // State to manage errors
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchPortfolioData();
  }

  fetchPortfolioData = async () => {
    try {
      const response = await axios.post('/services/get_all_services', {});
      this.setState({ portfolioData: response.data.responseData, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  render() {
    const { portfolioData, loading, error } = this.state;
    return (
      <>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <Header {...this.props} />
        <HeroPortfolio {...this.props} />
        {!loading && !error && <AllPortfolio data={portfolioData} />}
        <Discuss {...this.props} />
        <Footer />
      </>
    );
  }
}
