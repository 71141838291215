import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Button from 'elements/Button';
import { useNavigate } from 'react-router-dom';


export default function Hero() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [placeholderText, setPlaceholderText] = useState('Search through all our services');
  const [services, setServices] = useState([]);
  const placeholderOptions = [
    'Search through all our services',
    'Try "service"',
    'Find your best solution',
  ];
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
    let index = 0;
    let charIndex = 0;
    let currentText = '';
  
    const typeText = () => {
      if (charIndex < placeholderOptions[index].length) {
        currentText += placeholderOptions[index][charIndex];
        setPlaceholderText(currentText);
        charIndex += 1;
        setTimeout(typeText, 100);
      } else {
        setTimeout(() => {
          index = (index + 1) % placeholderOptions.length;
          currentText = '';
          charIndex = 0;
          setTimeout(typeText, 100);
        }, 2000);
      }
    };
  
    typeText();
  
    return () => clearTimeout(typeText);
  }, []);

  const fetchServices = async () => {
    const response = await axios.post('/services/get_all_services', {});
    setServices(response.data.responseData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      const results = services.filter((service) =>
        service.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredServices(results);
    } else {
      setFilteredServices([]);
    }
  };

  return (
    <section className="hero w-full h-screen flex flex-col items-center">
      <div className="text-center w-full max-w-4xl px-4">
        {/* Main Heading */}
        <h1 className="text-5xl sm:text-6xl text-theme-blue font-bold leading-tight mb-5 animate-slide-in">
          We Simplify Your
          <br />
          Entrepreneurial Journey
        </h1>

        {/* Subheading */}
        <p className="font-light text-xl text-gray-400 leading-relaxed mb-10">
          Enabling entrepreneurs to stay legally compliant.
        </p>

        {/* Search Component */}
        <div className="relative w-full max-w-2xl mx-auto">
          <input
            type="text"
            className="w-full px-6 py-4 text-lg text-gray-700 bg-white border border-gray-300 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-theme-purple"
            placeholder={placeholderText}
            value={searchTerm}
            onChange={handleSearch}
          />
          <button className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-theme-purple text-white px-4 py-2 rounded-full">
            <svg className="w-6 h-6" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-4.35-4.35M15 11a4 4 0 10-8 0 4 4 0 008 0z" />
            </svg>
          </button>

          {/* Dropdown for showing filtered services */}
          {filteredServices.length > 0 && (
            <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-20">
              {filteredServices.map((service, index) => (
                <li
                  key={index}
                  className="px-4 py-3 text-gray-700 hover:bg-gray-100 cursor-pointer border-b last:border-none"
                  onClick={() => navigate(`/service/${service._id}`)}
                >
                  {service.name}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* CTA Button */}
        <Button
          href="/services"
          type="link"
          className="w-max mx-auto flex mt-10 items-center px-14 py-5 text-white text-xl bg-theme-purple rounded-lg shadow-2xl hover:bg-dark-theme-purple transition duration-200"
        >
          See Our services
          <svg className="ml-2 w-7 h-7 text-white animate-bounce-x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Button>
      </div>
    </section>
  );
}
