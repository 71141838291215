import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Transactions from "./pages/Transactions";
// import DashBoard from "./pages/DashBoard/index";
import ServiceDetials from "./pages/DashBoard/ServicesDetails";
import ApplyForService from "./pages/DashBoard/ApplyForService";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";



function Client() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar}/>
            <Routes>
              {/* <Route path="/" element={<DashBoard />} /> */}
              <Route exact path="/service/:id" element={<ServiceDetials />} />
              <Route exact path="/apply/:id/:sub" element={<ApplyForService />} />
              <Route path="/" element={<Transactions />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Client;
