// First we need to import axios.js
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  // baseURL: 'https://ecregi.com/',
  baseURL: 'http://localhost:3001/',
});

// Add a request interceptor to include the token in the headers for every request
instance.interceptors.request.use(
  config => {
    // Retrieve the token from session storage
    const token = window.sessionStorage.getItem('token');
    // If token exists, add it to the headers
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // Handle request errors
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle expired JWT
instance.interceptors.response.use(
  response => {
    if (response && response.data.message === "Unauthorized") {
      alert('Your session has expired. Please log in again.');
      // Remove the expired token from session storage
      window.sessionStorage.removeItem('token');
      // Redirect to the login page
      window.location.href = '/login';
      return Promise.reject(error);
    }
    // If the response is successful, just return it
    return response;
  },
  error => {
    // Check if the error response indicates JWT expiration
    if (error.response && error.response.data.message === 'JWT expired') {
      // Handle the expired token scenario
      alert('Your session has expired. Please log in again.');
      // Remove the expired token from session storage
      window.sessionStorage.removeItem('token');
      // Redirect to the login page
      window.location.href = '/login'; // Redirect to login page using window.location.href
    }
    // Return any other error as a rejected promise
    return Promise.reject(error);
  },
);

// Export the configured Axios instance
export default instance;
