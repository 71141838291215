import React from 'react';
import { Box, Card, CardContent, Typography, Grid, Paper } from '@mui/material';
import { Dashboard as DashboardIcon, Pending as PendingIcon, Done as DoneIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const Dashboard = ({ data }) => {
  const { totalServices, finishedServices, pendingServices } = data;

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ bgcolor: '#e3f2fd', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Total Services
              </Typography>
              <Typography variant="h4" color="primary">
                {totalServices}
              </Typography>
              <DashboardIcon sx={{ fontSize: 50, color: '#0288d1' }} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ bgcolor: '#dcedc8', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Finished Services
              </Typography>
              <Typography variant="h4" color="success.main">
                {finishedServices}
              </Typography>
              <CheckCircleIcon sx={{ fontSize: 50, color: '#4caf50' }} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ bgcolor: '#ffebee', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Pending Services
              </Typography>
              <Typography variant="h4" color="warning.main">
                {pendingServices}
              </Typography>
              <PendingIcon sx={{ fontSize: 50, color: '#f44336' }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
