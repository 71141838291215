import React from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Dashboard from "./pages/dashboard";
import Services from "./pages/services";
import Invoices from "./pages/invoices";
import SignUp from "./pages/Appovals/Signups";
import AccountList from "./pages/AccountList/AccountList";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";

import { GlobalStateProvider } from './store';


function Admin() {
  const [theme, colorMode] = useMode();

  return (
    <GlobalStateProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ height: "800px" }}>
            <Sidebar isSidebar={true} />
            <main className="content">
              <Topbar setIsSidebar={() => {}} />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/services" element={<Services />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/sign_up" element={<SignUp />} />
                <Route path="/account_list" element={<AccountList />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </GlobalStateProvider>
  );
}

export default Admin;
