import { createContext, useContext, useEffect, useState } from "react";
import axios from "../../axiosConfig";
import { AuthContext } from "components/AuthContext";

const AssociateContext = createContext();

export const useAssociate = () => useContext(AssociateContext);

export const AssociateProvider = ({ children }) => {
  const [kycStatus, setKycStatus] = useState(null); // initially null to indicate loading
  const [kycData, setKycData] = useState();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchKycStatus = async () => {
      try {
        const response = await axios.post("/user/checkKYC", {userId: user.id});
        setKycStatus(response.data.status === 200);
        if (response.data.status === 200) setKycData(response.data.responseData);
      } catch (error) {
        console.error("Error fetching KYC status:", error);
        setKycStatus(false); // default to false in case of error
      }
    };

    fetchKycStatus();
  }, []);

  return (
    <AssociateContext.Provider value={{ kycStatus, kycData, setKycData, setKycStatus }}>
      {children}
    </AssociateContext.Provider>
  );
};
